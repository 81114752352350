<template>
  <div>
      <v-container class="py-10">
          <v-row>
              <v-col md="12" class="px-5">
                  <v-row>
                    <v-btn v-if="returnAbility('document:pay_installment')" color="success" rounded class="white--text" @click="$router.push(`newPayInstallment`);">
                      <v-icon>mdi-plus</v-icon>
                      {{ $t('hr.payment.add') }}
                    </v-btn>
                  </v-row>
              </v-col>
              <v-col md="3">
                  <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.from-date')" type="date"
                      v-model="filters.from" clearable>
                  </v-text-field>
              </v-col>
              <v-col md="3">
                  <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.to-date')" type="date"
                      v-model="filters.to" clearable>
                  </v-text-field>
              </v-col>
              <v-col md="3">
                  <AutoCompleteField :label="$t('account name')" end-point="/accounting/account/auto-complete"  
                  dense v-model="filters.account_id"/>
              </v-col>
              <v-col md="3">
                  <AutoCompleteField :label="$t('hr.loan.cash')" end-point="/accounting/account/auto-complete" :cash="1"  
                  dense v-model="filters.cash_account_id"/>
              </v-col>
              <v-col md="3">
                <v-text-field :dark="$store.state.isDarkMode" :label="$t('Value')" type="number" v-model="filters.value"></v-text-field>
              </v-col>
              <v-col md="3">
                  <AutoCompleteField :label="$t('Invoice Name')" end-point="/inventories/invoice-types/index"
                  dense v-model="filters.invoice_type"/>
              </v-col>
              <v-col md="3">
                <v-checkbox :dark="$store.state.isDarkMode" :label="$t('Is Paid')" class="mx-4 mb-4" v-model="filters.is_paid" hide-details></v-checkbox>
              </v-col>
              <v-col md="3"></v-col>
              <v-col md="3" class="mb-3 py-0">
                  <v-btn color="green" dark block @click="fetchData(1)">{{ $t('search') }}</v-btn>
              </v-col>
              <v-col md="3" class="mb-3 py-0">
                  <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
              </v-col>
              <v-col md="3" class="mb-3 py-0" v-if="returnAbility('installment_payment:export')">
                  <ExportExcel end-point="/inventories/installment-payment/export" :params="filters" />
              </v-col>
              <v-col md="12">
                  <v-container v-if="returnAbility('installment_payment:index')" id="regular-tables" fluid tag="section">
    <v-simple-table :dark="$store.state.isDarkMode">
      <thead class="table-heading" v-if="!tableOptions.loading">
        <tr>
          <th v-for="(name, i) in tableOptions.tableHeaders" :key="i">
            {{ $t(name) }}
          </th>
        </tr>
      </thead>
      <div class="table__spinnerWrapper" v-if="tableOptions.loading">
        <app-spinner></app-spinner>
      </div>
      <v-fade-transition mode="out-in" :group="true" tag="tbody" v-if="!tableOptions.loading">
        <template >
         
          <tr v-for="(item, idx) in tableOptions.tableData" :key="idx">
            
            <td>{{ item.id }}</td>
            <td>{{ item.installment_date }}</td>
            <td>{{ item.payment_date }}</td>
            <td>{{ item.account }}</td>
            <td>{{ item.value }}</td>
            <td>{{ item.invoice_type }}</td>
            <td>{{ item.cash_account }}</td>
            <td>{{ item.is_paid }}</td>
            <td>
              <deleteVar v-if="returnAbility('installment_payment:delete')"  @record-deleted="fetchData" class="py-2" path="inventories/installment-payment" :id="item.id" />
            </td>
            
          </tr>
        </template>
      </v-fade-transition>
    </v-simple-table>
    <div class="d-flex justify-center mt-5" >
      <v-row>
        <!-- <v-col md="5 ">
          <v-switch
            @change="goToAll"    
            v-model="is_all"
            color="green"
            :label="$t('Show All')"
          ></v-switch>
        </v-col> -->
      <app-pagination v-if="(!tableOptions.loading)"  :totalPages="tableOptions.tableMeta.total" :page="tableOptions.tableMeta.page"
        @PaginationValue="applyPagination($event)"></app-pagination>
      </v-row>
    </div>
  </v-container>
 
              </v-col>
          </v-row>
      </v-container>
  </div>
</template>
<script>

import axios from "axios";
import ExportExcel from "../../components/core/ExportExcel.vue";
import ExportPdf from "../../components/core/ExportPdf.vue";
import deleteVar from "../../components/core/delete.vue";
import AutoCompleteField from "../../components/core/AutoCompleteField.vue";
import spinner from "../../../../components/base/spinner.vue";
import pagination from "../../../../components/base/pagination.vue";

export default {
  components: {
      ExportExcel,
      ExportPdf,
      deleteVar,
      AutoCompleteField,
      appSpinner: spinner,
      appPagination: pagination,
  },
  data() {
      return {
          loading: false,
          filters: {
              from: null,
              to: null,
              account_id: null,
              cash_account_id: null,
              value: null,
              invoice_type: null,
              is_paid: null,
          },         
          tableOptions: {
              tableData: [],
              tableHeaders: ["id",  "Installment Date","hr.employee.date-of-payment","account name", "Value", "Invoice Name", "hr.loan.cash", "status", "settings"],
              accessTableData: [["id"], ["installment_date"], ["payment_date"], ["account"], ["value"], ["invoice_type"],["cash_account"],["is_paid"],],
              loading: false,
              tableMeta: {
                  total: null,
                  page: null,
              },
          },
      };
  },
  methods: {
      applyPagination(paginationValue) {
    this.page = paginationValue;
    this.fetchData();
  },
      async fetchData(page) {
          this.tableOptions.loading = true;
          const filterTemp = {};
          Object.keys(this.filters).forEach((key) => {
              if (this.filters[key]) filterTemp[key] = this.filters[key];
          });
          let params = {
              size: 10,
              page: page,
              ...filterTemp,
          };
          const response = await axios.get("/inventories/installment-payment/index", { params });
          if (response.status == 200) {
              this.tableOptions.tableData = response.data.data,
              this.tableOptions.tableMeta.total = response.data.total;
              this.tableOptions.tableMeta.page = response.data.current_page;
          }
          this.tableOptions.loading = false;
      },
      clearFilter() {
          this.filters.cash_account_id = undefined;
          this.filters.value = undefined;
          this.filters.from = undefined;
          this.filters.to = undefined;
          this.filters.account_id = undefined;
          this.filters.is_paid = undefined;
          this.filters.invoice_type = undefined;
          this.fetchData(1);
      },
  },
  created() {
      this.fetchData(1);
  },
};
</script>

<style></style>












<!-- 

<template>
  <v-container class="px-10">
    <v-row justify="center">
      <v-col md="12">
        
      </v-col>
      <v-col class="mt-8">
        <h3 class="mb-2">{{ $t($router.currentRoute.name) }}</h3>
      
        <v-data-table :dark="$store.state.isDarkMode" :search="search" :headers="headers" id="table" :items="data"
          :loading="$store.state.payinstallment.loading" style="border: 1px solid #999"
          :options.sync="$store.state.payinstallment.options"
          :server-items-length="$store.state.payinstallment.serverTotal" disable-sort>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex justify-center">
              <v-icon color="red" style="cursor: pointer" class="px-2" @click="handleDelete(item)">mdi-delete
              </v-icon>
            ///<v-icon color="blue" class="mx-2" style="cursor: pointer" @click="handleDisplay(item)">mdi-eye
        ///      </v-icon> ////////////////////////////////////////////
            </div>

          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <PayInstallmentModal v-model="display" :item="item" :isDis="isDis"></PayInstallmentModal>
  </v-container>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import PayInstallmentModal from "@/views/dashboard/modules/pay-installments/PayInstallmentModal.vue";

export default {
  components: { PayInstallmentModal },
  data() {
    return {
      search: '',
      display: false,
      item: null,
      isDis: false,
    };
  },
  mounted() {
    this.fetch()
  },
  watch: {
    "$store.state.payinstallment.options": function () {
      this.fetch()
    },
  },
  computed: {
    ...mapGetters({
      data: 'payinstallment/data',
      headers: 'payinstallment/headers'
    })
  },
  methods: {
    ...mapActions({
      fetch: 'payinstallment/fetch',
      delete: 'payinstallment/delete',
      setPayment: 'payinstallment/setPayment'
    }),
    handleDisplay(item) {
      this.display = true
      this.isDis = true
      console.log(item);
      this.setPayment(item)
      this.item = item
    },
    async handleDelete(item) {
      await this.delete(item.id)
    },
    // handleEdit(item) {
    //   this.item = item
    //   this.display = true
    // },
  },
};
</script>
<style scoped>
#table>>>th,
#table>>>td {
  text-align: center !important;
}
</style> -->
