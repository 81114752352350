<template>
    <div>
        <v-icon v-if="isIcon" :loading="loading" @click="exportPdf" color="red">mdi-file-pdf-box</v-icon>
    </div>
</template>
<script>
import axios from 'axios';
import { saveAs } from 'file-saver';
export default {
    props: {
        isIcon: {
            type: Boolean,
            default: false,
        },
        endPoint: {
            type: String,
        },
        id: {
            type: Number,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        async exportPdf() {
            try {
                this.loading = true;
                const pdf = await axios.get(`${this.endPoint}/${this.id}`, {
                    responseType: 'blob',
                });
                saveAs(pdf.data, "file.pdf");
            } catch (error) {
                console.error("Errore", error);
            }
            finally {
                this.loading = false;
            }
        },
    },
};
</script>
<style></style>